body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .home-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  }
  
  .upload-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .upload-link:hover {
    background-color: #0056b3;
  }
  
  /* styles.css */

/* Styling for file input (upload) */
input[type="file"] {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #000000;
  }
  
 /* Style for the container of radio buttons */
.radio-container {
    flex-direction: column;
  }
  
  /* Style for individual radio buttons */
  .radio-button {
    margin-bottom: 10px;
  }
  
  /* Style for the label associated with radio buttons */
  .radio-label {
    margin-left: 10px;
  }
  
  .result {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    color: #333;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  button {
    padding: 10px 15px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  