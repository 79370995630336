body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .home-container {
    width: 100%;
    margin: 0 auto;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  }
  
  .upload-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .upload-link:hover {
    background-color: #0056b3;
  }
  